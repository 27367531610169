import PROPERTIES from "properties";
import { ScreenContainer, TabTitle } from "../settings/Settings";
import StyledToggleButtonGroup from "components/StyledToggleButtonGroup";
import { Mitigation, useAlertsStoreContext } from "stores/AlertsProvider";
import { Category, LoadOptions, Order, Severity } from "stores/AlertsStore";
import { Button, Container, styled } from "@mui/material";
import { AlertKey, AlertsTable } from "../home/AlertsTable";
import { useEffect, useState } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { TitleContainer } from "../home/Home";
import { useAccountsStoreContext } from "stores/AccountsProvider";
import StyledSelect from "components/StyledSelect";

const { EVENTS } = PROPERTIES
const DEFAULT_ROWS_PER_PAGE = 10

export default function Events() {

    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE)

    const alertsStore = useAlertsStoreContext()
    const accountsStore = useAccountsStoreContext()

    const { severity, category, order, orderBy, mitigation } = alertsStore
    const { accounts, selectedAccountIndex } = accountsStore

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    // Call for alerts data from the start whenever the filters, rowsPerPage or sorting changes
    useEffect(() => {
        const options: LoadOptions = {
            severitiesFilter: severity,
            categoriesFilter: category,
            order: order,
            sortBy: orderBy,
            mitigation: mitigation
        }
        alertsStore.setLoadOptions(options)

        const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        alertsStore.loadAlerts(rowsPerPage, "", selectedAccountId)
    }, [severity, category, rowsPerPage, order, orderBy, mitigation, selectedAccountIndex])

    function handlePageChange(newPage: number, oldPage: number) {
        const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        if ((newPage - oldPage) === 1)
            alertsStore.loadAlerts(rowsPerPage, alertsStore.cursorAfter, selectedAccountId)
        else if ((newPage - oldPage) > 1)
            alertsStore.loadAlerts(rowsPerPage, alertsStore.cursorLast, selectedAccountId)
        else if (newPage === 0)
            alertsStore.loadAlerts(rowsPerPage, "", selectedAccountId)
        else if ((newPage - oldPage) === -1)
            alertsStore.loadAlerts(rowsPerPage, alertsStore.cursorBefore, selectedAccountId)
    }

    function handleSort(order: Order, orderBy: string) {
        alertsStore.setOrder(order)
        alertsStore.setOrderBy(orderBy as AlertKey)
    }

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })
    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]
    
    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}}>
            <Container disableGutters style={{marginLeft: 0, maxWidth: 'inherit'}}>
                <TitleContainer style={{marginRight: 0}}>
                    <TabTitle>{EVENTS.TITLE}</TabTitle>
                    <StyledSelect
                        label="Account"
                        handleOnChange={accountsStore.setSelectedAccountIndex}
                        options={selectOptions}
                        value={selectedAccountIndex}/>
                </TitleContainer>
                <Container disableGutters style={{maxWidth: 'inherit'}}>    
                    <StyledToggleButtonGroup
                        value={severity}
                        handleOnChange={(value) => alertsStore.setSeverity(value as Severity)}
                        options={severitiesFilterOptions}
                    />
                </Container>
                <Container disableGutters style={{maxWidth: 'inherit'}}>
                    { severity !== 'siem' &&
                        <HDMToggleGroup
                            value={category}
                            handleOnChange={(value) => alertsStore.setCategory(value as Category)}
                            options={categoriesFilterOptions}
                        />
                    }
                </Container>
                <Container disableGutters style={{maxWidth: 'inherit', display: 'flex', justifyContent: 'space-between'}}>
                    <StyledToggleButtonGroup
                        value={mitigation}
                        handleOnChange={(value) => alertsStore.setMitigation(value as Mitigation)}
                        options={mitigationFilterOptions}
                    />
                    <Button startIcon={<FileDownloadIcon />} onClick={alertsStore.downloadAlertsCSV}>
                        {EVENTS.DOWNLOAD_CSV}
                    </Button>
                </Container>
                <AlertsTable
                    alerts={alertsStore.alerts}
                    totalAlertsCount={alertsStore.total}
                    loading={alertsStore.loading}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={setRowsPerPage}
                    onSort={handleSort}
                    rowsPerPage={rowsPerPage}   
                    order={alertsStore.order}
                    orderBy={alertsStore.orderBy}
                    severityFilter={alertsStore.severity}
                    categoryFilter={alertsStore.category} />
            </Container>
        </ScreenContainer>
    )
}

export const severitiesFilterOptions = [
    { value: 'ransomware', label: 'Ransomware'},
    { value: 'ids', label: 'Intrusion'},
    { value: 'siem', label: 'SIEM'},
    { value: 'all', label: 'All'},
]

export const categoriesFilterOptions = [
    { value: 'hardening', label: 'Preemption'},
    { value: 'detection', label: 'Blocking'},
    { value: 'mitigation', label: 'Remediation'},
    { value: 'all', label: 'All'},
]

const mitigationFilterOptions = [
    { value: 'blocked', label: 'Blocked'},
    { value: 'unblocked', label: 'Not blocked'},
    { value: 'all', label: 'All'}
]

const HDMToggleGroup = styled(StyledToggleButtonGroup)`
    margin-bottom: 10px;
`
